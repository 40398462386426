export default {
   "billing": {
      "entrypoint": {
         "fees": {
            "noc_fee": {
               "active": false,
               "amount": 2.5,
               "description": "Fee for using EntryPoint NOC"
            },
            "saas_fee": {
               "active": false,
               "amount": 2.5,
               "description": "Fee for using EntryPoint Software"
            },
         }
      },
      "external": {
         "cc_initial_processing_fee": {
             "active": true,
             "amount": 0,
             "currency": "usd",
             "description": "Initial Credit Card Processing Fee",
             "type": "one_time"
         },
         "cc_recurring_processing_fee": {
             "active": true,
             "amount": 0,
             "currency": "usd",
             "description": "Recurring Credit Card Processing Fee",
             "type": "recurring",
             "recurring": {
                 "interval": "month",
                 "interval_count": 1
             }
         },
         "utility_fee": {
             "active": true,
             "amount": 0,
             "currency": "usd",
             "description": "Utility Fee",
             "type": "recurring",
             "recurring": {
                 "interval": "month",
                 "interval_count": 1
             }
         }
      },
      "foxy_cart": {
         "cart_url": "",
         "store_secret": "",
      },
      "stripe": {
         "api_key": "",
         "cc_initial_processing_fee_id": "",
         "cc_recurring_processing_fee_id": "",
         "connect_account": "",
         "publishable_api_key": "",
         "utility_fee_id": "",
         "webhook_endpoint_secret": ""
      }
   },
   "constants":{
      "ansible_host_file":"ansible_hosts"
   },
   "crypto":{
      "country_code":"US",
      "state_code":""
   },
   "docker":{
      "allow_ipv6_connections": true,
      "docker_swarm_join_token":"",
      "docker_swarm_manager_ip":"ref:networks/management_network/reserved/flowops/ip_address",
      "docker_swarm_manager_port":"2377",
      "docker_swarm_worker_ips":{
         "compliance":"ref:networks/management_network/reserved/compliance_portal/ip_address",
         "repo":"ref:networks/management_network/reserved/config_repo/ip_address",
         "walled_garden":"ref:networks/management_network/reserved/subscriber_portal/ip_address"
      },
      "extra_hosts": {
         "ipsec-vpn-server": [{ "host": "", "ip": ""}],
         "operator_compliance_dns_0": [{ "host": "", "ip": ""}],
         "operator_compliance_portal": [{ "host": "", "ip": ""}],
         "operator_device_identification_0": [{ "host": "", "ip": ""}],
         "operator_device_repo_0": [{ "host": "", "ip": ""}],
         "operator_flowops_api": [{ "host": "", "ip": ""}],
         "operator_flowops_db": [{ "host": "", "ip": ""}],
         "operator_flowops_proxy": [{ "host": "", "ip": ""}],
         "operator_management_dns_0": [{ "host": "", "ip": ""}],
         "operator_management_dns_1": [{ "host": "", "ip": ""}],
         "operator_management_ldap_0": [{ "host": "", "ip": ""}],
         "operator_management_ntp_0": [{ "host": "", "ip": ""}],
         "operator_management_ntp_1": [{ "host": "", "ip": ""}],
         "operator_management_portal": [{ "host": "", "ip": ""}],
         "operator_management_proxy": [{ "host": "", "ip": ""}],
         "operator_management_radius_0": [{ "host": "", "ip": ""}],
         "operator_management_radius_1": [{ "host": "", "ip": ""}],
         "operator_management_redis_0": [{ "host": "", "ip": ""}],
         "operator_management_services_proxy": [{ "host": "", "ip": ""}],
         "operator_network_utils": [{ "host": "", "ip": ""}],
         "operator_remote_ssh": [{ "host": "", "ip": ""}],
         "operator_snmp_trap_monitor_0": [{ "host": "", "ip": ""}],
         "operator_ssh_proxy": [{ "host": "", "ip": ""}],
         "operator_stripe_webhooks": [{ "host": "", "ip": ""}],
         "operator_subscriber_dns_0": [{ "host": "", "ip": ""}],
         "operator_subscriber_portal": [{ "host": "", "ip": ""}],
         "operator_walled_garden_dns_0": [{ "host": "", "ip": ""}],
         "operator_walled_garden_proxy": [{ "host": "", "ip": ""}],
         "operator_websocket_server": [{ "host": "", "ip": ""}],
         "zabbix_agent": [{ "host": "", "ip": ""}],
         "zabbix_database": [{ "host": "", "ip": ""}],
         "zabbix_server": [{ "host": "", "ip": ""}],
         "zabbix_web": [{ "host": "", "ip": ""}]
      },
      "image_tag_overrides": {
         "ipsec-vpn-server": "",
         "operator_compliance_dns_0": "",
         "operator_compliance_portal": "",
         "operator_device_identification_0": "",
         "operator_device_repo_0": "",
         "operator_flowops_api": "",
         "operator_flowops_db": "",
         "operator_flowops_proxy": "",
         "operator_management_dns_0": "",
         "operator_management_dns_1": "",
         "operator_management_ldap_0": "",
         "operator_management_ntp_0": "",
         "operator_management_ntp_1": "",
         "operator_management_portal": "",
         "operator_management_proxy": "",
         "operator_management_radius_0": "",
         "operator_management_radius_1": "",
         "operator_management_redis_0": "",
         "operator_management_services_proxy": "",
         "operator_network_utils": "",
         "operator_remote_ssh": "",
         "operator_snmp_trap_monitor_0": "",
         "operator_ssh_proxy": "",
         "operator_stripe_webhooks": "",
         "operator_subscriber_dns_0": "",
         "operator_subscriber_portal": "",
         "operator_walled_garden_dns_0": "",
         "operator_walled_garden_proxy": "",
         "operator_websocket_server": "",
         "zabbix_agent": "",
         "zabbix_database": "",
         "zabbix_server": "",
         "zabbix_web": ""
      },
      "use_lets_encrypt":true,
      "volume_paths":{
         "dev":{
            "local": {
               "flowops_api": "/var/openedge/operator/flowops",
               "flowops_proxy": "/var/openedge/operator/proxy_to_flowops",
               "management_portal": "/var/openedge/operator/management",
               "network_utils": "/var/openedge/operator/network_utils",
               "stripe_webhooks": "/var/openedge/operator/stripe_webhooks"
            }
         },
         "device_config": "/var/openedge/docker/operator/device_config",
         "management_dns_config": "/var/openedge/docker/operator/management_dns_configs",
         "media":{
            "plan":"/shared/media/plan",
            "provider":"/shared/media/provider",
            "service":"/shared/media/service"
         },
         "ssl":"/shared/ssl"
      }
   },
   "ep_file_storage": {
      "api_key": "operator",
      "api_secret": "664cb612be979f63a425",
      "device_manager_version": "",
      "event_client_version": "",
      "url": "https://api.storage.entpnt.dev"
   },
   "flowops_authentication": {
      "enabled": false,
      "timeout": 300
   },
   "flowops_proxy": {
      "api_key": "",
      "api_secret": "",
      "jwt_secret": ""
   },
   "local":{
      "constants":{
         "api_key":"",
         "api_secret":"",
         "deploy_log":"deploy_app.log",
         "domain":"",
         "environment":"PRODUCTION",
         "event_client_port":5561,
         "event_manager_port":5560,
         "internal_ssh_domain":"operator_ssh_proxy:5000",
         "network_utils_api_key":"",
         "network_utils_api_secret":"",
         "salt":"",
         "sim_card_default_version":"1",
         "site_name":"FlowOps",
         "ssh_domain":"",
         "vsim_manager_port":4123
      },
      "email":{
         "support":""
      },
      "entrypoint_communicator":{
         "base_port":"8881",
         "country_code":"US",
         "state_code":""
      },
      "general":{
         "databases":{
            "default":{
               "database":"flowops",
               "host":"operator_flowops_db",
               "password":"",
               "port":3306,
               "username":""
            }
         }
      },
      "messages":{
         "subscription":{
            "email":true,
            "subscribe":{
               "provider_msg":"A subscribe event was triggered for service: {{SERVICE_NAME}} on {{DATE}}.\n\n{{USER_CONTACT_INFO}}",
               "provider_subject":"Subcription Created in {{OPERATOR_NAME}} Network",
               "user_msg":"A subscribe event was triggered for service: {{SERVICE_NAME}} on {{DATE}}. If you didn't initiate this review account settings to ensure no one else has access.\n\n{{PROVIDER_CONTACT_INFO}}",
               "user_subject":"Subcription Created in {{OPERATOR_NAME}} Network"
            },
            "unsubscribe":{
               "provider_msg":"An unsubscribe event was triggered for service: {{SERVICE_NAME}} on {{DATE}}.\n\n{{USER_CONTACT_INFO}}",
               "provider_subject":"Subcription Canceled in {{OPERATOR_NAME}} Network",
               "user_msg":"An unsubscribe event was triggered for service: {{SERVICE_NAME}} on {{DATE}}. If you didn't initiate this review account settings to ensure no one else has access.\n\n{{PROVIDER_CONTACT_INFO}}",
               "user_subject":"Subcription Canceled in {{OPERATOR_NAME}} Network"
            }
         }
      },
      "services":{
         "compliance_dns":{
            "internal_urls":[
               "http://operator_compliance_dns_0"
            ],
            "dhcp":{
               "lease_time":"12h",
               "range_end_ip":"10.80.0.254",
               "range_start_ip":"10.80.0.4"
            }
         },
         "management_dns":{
            "api_key":"",
            "api_secret":"",
            "instances":{
               "operator_management_dns_0":{
                  "external_url":""
               },
               "operator_management_dns_1":{
                  "external_url":""
               }
            }
         },
         "management_ntp":{
            "api_key":"",
            "api_secret":"",
            "instances":{
               "operator_management_ntp_0":{
                  "external_url":""
               },
               "operator_management_ntp_1":{
                  "external_url":""
               }
            }
         },
         "walled_garden_dns":{
            "dhcp":{
               "lease_time":"12h",
               "range_end_ip":"10.64.0.254",
               "range_start_ip":"10.64.0.4"
            },
            "internal_urls":[
               "http://operator_walled_garden_dns_0"
            ]
         }
      },
      "smtp":{
         "host":"",
         "password":"",
         "protocol":"ssl",
         "username":""
      },
      "urls":{
         "event_manager_url":"",
         "external_site_url":"",
         "flowops_url":"",
         "flowops_proxy_url":"",
         "internal_flowops_url":"http://operator_flowops_api",
         "internal_flowops_proxy_url":"http://operator_flowops_proxy",
         "internal_management_url":"http://operator_management_portal",
         "internal_subscriber_url":"http://operator_subscriber_portal",
         "internal_websocket_url":"ws://operator_websocket_server:8888",
         "management_url":"",
         "network_utils_url":"http://localhost:8053",
         "northstar_url":"https://northstar.entpnt.com",
         "remote_ssh_url":"http://operator_remote_ssh",
         "secureops_url":"http://operator_device_identification_0",
         "subscriber_landing_url":"",
         "subscriber_url":"",
         "support_ticket_url":""
      }
   },
   "networks":{
      "ipv6_suffix": "",
      "capacity_network":{
         "details":{
            "cidr":"10.160.0.0/19",
            "gateway":"10.160.0.1",
            "ipv6_gateway":"",
            "name":"Network Capacity"
         },
         "reserved":{
            "portal":{
               "ip_address":"10.160.0.1",
               "ipv6_address":"",
               "name":"Network Capacity Test"
            }
         }
      },
      "compliance_network":{
         "details":{
            "allow_ranges":[
               "10.80.0.0/23"
            ],
            "cidr":"10.80.0.0/12",
            "gateway":"10.80.0.1",
            "ipv6_gateway":"",
            "name":"Compliance Network"
         },
         "reserved":{
            "portal":{
               "ip_address":"10.80.0.1",
               "ipv6_address":"",
               "name":"Portal"
            }
         }
      },
      "import_network":{
         "details":{
            "allow_ranges":[
               "10.52.0.0/22"
            ],
            "cidr":"10.52.0.0/22",
            "gateway":"10.52.0.1",
            "ipv6_gateway":"",
            "name":"Import Network"
         },
         "reserved":{
            "import":{
               "ip_address":"10.48.0.1",
               "ipv6_address":"",
               "name":"Import Network"
            }
         }
      },
      "inband_network":{
         "details":{
            "allow_ranges":[
               "10.96.0.0/12"
            ],
            "cidr":"10.96.0.0/12",
            "gateway":"10.96.0.1",
            "ipv6_gateway":"",
            "name":"Inband Network"
         },
         "reserved":{
            "vrouter":{
               "ip_address":"10.96.0.1",
               "ipv6_address":"",
               "name":"vRouter"
            }
         }
      },
      "management_network":{
         "details":{
            "allow_ranges":[
               "10.0.0.0/24"
            ],
            "name":"Management Network",
            "cidr":"10.0.0.0/24",
            "gateway":"10.0.0.1",
            "ipv6_gateway":""
         },
         "reserved":{
            "ansible":{
               "ip_address":"10.0.0.11",
               "ipv6_address":"",
               "name":"Ansible"
            },
            "auth":{
               "ip_address":"10.0.0.13",
               "ipv6_address":"",
               "name":"Authentication Server"
            },
            "capacity":{
               "ip_address":"10.0.0.14",
               "ipv6_address":"",
               "name":"Network Capacity Service"
            },
            "compliance_portal":{
               "ip_address":"10.0.0.102",
               "ipv6_address":"",
               "name":"Compliance Portal"
            },
            "config_repo":{
               "ip_address":"10.0.0.9",
               "ipv6_address":"",
               "name":"Configuration Repository"
            },
            "database":{
               "ip_address":"127.0.0.1",
               "name":"Database"
            },
            "file_share":{
               "ip_address":"10.0.0.16",
               "ipv6_address":"",
               "name":"File Share"
            },
            "firewall":{
               "ip_address":"10.0.0.1",
               "ipv6_address":"",
               "name":"Firewall"
            },
            "flowops":{
               "ip_address":"10.0.0.2",
               "ipv6_address":"",
               "name":"FlowOps"
            },
            "import":{
               "ip_address":"10.0.0.104",
               "ipv6_address":"",
               "name":"Import Server"
            },
            "ldap":{
               "ip_address":"ref:networks/management_network/reserved/flowops/ip_address",
               "name":"LDAP"
            },
            "log":{
               "ip_address":"10.0.0.7",
               "ipv6_address":"",
               "name":"Log Server"
            },
            "management":{
               "ip_address":"ref:networks/management_network/reserved/flowops/ip_address",
               "name":"Management"
            },
            "management_services_1":{
               "ip_address":"10.0.0.3",
               "ipv6_address":"",
               "name":"Management Services 1"
            },
            "management_services_2":{
               "ip_address":"10.0.0.4",
               "ipv6_address":"",
               "name":"Management Services 2"
            },
            "openvas":{
               "ip_address":"10.0.0.6",
               "ipv6_address":"",
               "name":"OpenVAS"
            },
            "proxmox_backup":{
               "ip_address":"10.0.0.106",
               "ipv6_address":"",
               "name":"Proxmox Backup Server"
            },
            "siem":{
               "ip_address":"10.0.0.17",
               "ipv6_address":"",
               "name":"SIEM Server"
            },
            "subscriber_portal":{
               "ip_address":"10.0.0.101",
               "ipv6_address":"",
               "name":"Subscriber Portal"
            },
            "tftp":{
               "ip_address":"10.0.0.8",
               "ipv6_address":"",
               "name":"TFTP Server"
            },
            "unifi_controller":{
               "ip_address":"10.0.0.12",
               "ipv6_address":"",
               "name":"Unifi Controller"
            },
            "vnc":{
               "ip_address":"10.0.0.10",
               "ipv6_address":"",
               "name":"VNC Server"
            },
            "vrouter":{
               "ip_address":"10.0.0.254",
               "ipv6_address":"",
               "name":"Vyos Virtual Router"
            },
            "vxlan":{
               "ip_address":"10.0.0.106",
               "ipv6_address":"",
               "name":"VxLAN Connection"
            },
            "xrdp":{
               "ip_address":"10.0.0.15",
               "ipv6_address":"",
               "name":"XRDP Connection"
            },
            "zabbix":{
               "ip_address":"10.0.0.5",
               "ipv6_address":"",
               "name":"Zabbix Monitoring"
            },
            "zabbix_proxy":{
               "ip_address":"10.0.0.25",
               "ipv6_address":"",
               "name":"Zabbix Monitoring Proxy"
            }
         }
      },
      "provision_network":{
         "details":{
            "cidr":"10.48.0.0/22",
            "gateway":"10.48.0.1",
            "ipv6_gateway":"",
            "name":"Provisioning Network"
         },
         "reserved":{
            "walled_garden":{
               "ip_address":"10.48.0.1",
               "ipv6_address":"",
               "name":"Walled Garden"
            }
         }
      },
      "proxmox_network":{
         "details":{
            "cidr":"192.168.1.0/25",
            "gateway":"192.168.1.1",
            "ipv6_gateway":"",
            "name":"Proxmox Network"
         },
         "reserved":{
            "node1":{
               "ip_address":"192.168.1.239",
               "ipv6_address":"",
               "name":"Node 1"
            },
            "node2":{
               "ip_address":"192.168.1.241",
               "ipv6_address":"",
               "name":"Node 2"
            },
            "node3":{
               "ip_address":"192.168.1.243",
               "ipv6_address":"",
               "name":"Node 3"
            }
         }
      },
      "public_network":{
         "details":{
            "cidr":"192.168.1.0/25",
            "gateway":"192.168.1.1",
            "ipv6_gateway":"",
            "name":"Public Network"
         },
         "reserved":{
            "firewall":{
               "ip_address":"192.168.1.240",
               "ipv6_address":"",
               "name":"Firewall"
            },
            "gre_tunnel":{
               "remote_ip_address":"199.104.67.3",
               "local_ip_address":"",
               "a_side_ipv6_address":"",
               "z_side_ipv6_address":"",
               "name":"GRE Tunnel"
            },
            "proxmox_host": {
              "ip_address": "",
              "ipv6_address": "",
              "name": ""
          }
         }
      },
      "vbg_network":{
         "details":{
            "cidr":"10.128.0.0/10",
            "gateway":"10.128.0.1",
            "ipv6_gateway":"",
            "name":"VBG Network"
         },
         "label": "3"
      },
      "vxlan_network":{
         "remote_ip":"",
         "remote_network":"",
         "local_ip":"",
         "local_gateway":""
      },
      "walled_garden_network":{
         "details":{
            "allow_ranges":[
               "10.64.0.0/12"
            ],
            "cidr":"10.64.0.0/12",
            "gateway":"10.64.0.1",
            "ipv6_gateway":"",
            "name":"Walled Garden Network"
         },
         "reserved":{
            "portal":{
               "ip_address":"10.64.0.1",
               "ipv6_address":"",
               "name":"Portal"
            }
         }
      }
   },
   "sentry": {
      "environment": ""
   },
   "services":{
      "ansible":{
         "external_url":"",
         "internal_url":"https://operator_ansible_tower_api",
         "password":"",
         "supported_products":[

         ],
         "username":"",
         "vbg_inventories":"vbgs",
         "vbg_job_template":"VBG Testing"
      },
      "device_identification":{
         "internal_urls":[
            "http://operator_device_identification_0"
         ]
      },
      "device_repo":{
         "internal_urls":[
            "http://operator_device_repo_0"
         ],
         "password":"",
         "username":""
      },
      "management_ldap":{
         "admin_password":"",
         "internal_urls":[
            "http://operator_management_ldap_0"
         ]
      },
      "management_radius":{
         "api_key":"",
         "api_secret":"",
         "client_secret":"",
         "instances":{
            "operator_management_radius_0":{
               "external_url":""
            }
         },
         "ip":"ref:networks/management_network/reserved/flowops/ip_address",
         "port":"1812",
         "timeout":"30"
      },
      "management_redis":{
         "internal_urls":[
            "http://operator_management_redis_0"
         ],
         "password":""
      },
      "remote_control":{
         "internal_url": "http://operator_ssh_api/ssh",
         "password":"",
         "username":"",
         "timeout": 4,
      },
      "snmp_trap_monitor":{
         "internal_urls":[
            "http://operator_snmp_trap_monitor_0"
         ],
         'http_port': 80,
         'snmp_listen_address': '0.0.0.0',
         'snmp_listen_port': '162'
      },
      "zabbix":{
        "admin_password": "EntryPoint#",
        "db_password": "EntryPoint#",
        "db_root_password": "EntryPoint#",
        "db_username": "db_entpnt",
        "password": "EntryPoint#",
        "username": "entpnt"
      }
   },
   "templates":{
      "management":{
         "site_name":"Management Portal",
         "theme":"default"
      },
      "provider":{
         "site_name":"Provider Portal",
         "theme":"default"
      },
      "subscriber":{
         "site_name":"Subscriber Portal",
         "support_ticket_url":"ref:local/urls/support_ticket_url",
         "theme":"default"
      }
   },
   "urls":{
      "repo_base_url":""
   },
   "walledgarden_flows":{
      "bootstrap":{
         "bootstrap_drop_ctag":{
            "in":{
               "1":"FLOOD"
            }
         },
         "bootstrap_pop_double_label": {
            "in": {
                "1": "FLOOD"
            },
            "protocol": ",arp"
         },
         "bootstrap_pop_network_range": {
            "in": {
                "1": "FLOOD"
            },
            "source_ip_address": "ref:networks/walled_garden_network/details/cidr"
         },
         "bootstrap_push_double_label":{
            "in":{
               "2":1,
               "3":1,
               "4":1,
               "5":1
            }
         },
         "label":{
            "network_operator_label":5,
            "priority":10,
            "service_provider_label":10
         }
      },
      "capacity":{
         "label":{
            "network_operator_label":7,
            "priority":20,
            "service_provider_label":20
         }
      },
      "compliance":{
         "bootstrap_pop_double_label":{
            "in":{
               "1":"FLOOD"
            }
         },
         "bootstrap_push_double_label":{
            "in":{
               "2":1,
               "3":1,
               "4":1,
               "5":1
            }
         },
         "label":{
            "network_operator_label":5,
            "priority":65535,
            "service_provider_label":11
         }
      },
      "import":{
         "label":{
            "network_operator_label":6,
            "priority":20,
            "service_provider_label":20
         }
      },
      "lan_management":{
         "label":{
            "network_operator_label":5,
            "priority":20,
            "service_provider_label":20
         }
      }
   }

}
